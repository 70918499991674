<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown"
    class="px-10"
  >
    <v-row>
      <v-col>
        <h1 class="mt-5">イベント情報の管理</h1>
        <banner-hint>
          イベントページとホーム画面に表示できるイベント情報パーツを使ってお客様に新しいイベント情報を通知できます。<br />
          また、LINE友だち登録しているお客様にイベント情報を一斉配信してイベント効果・実客数のアップを狙いましょう。
        </banner-hint>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <!-- 新規追加ボタン -->
        <div class="mb-7">
          <v-btn
            depressed
            color="primary"
            @click="addEvent()"
          >イベント情報を追加</v-btn>
        </div>

        <v-card v-if="!events.length" flat>
          <v-card-text>イベント情報が登録されていません。</v-card-text>
        </v-card>

        <v-card
          class="my-5 pt-10 pb-7 px-6"
          elevation="1"
          v-for="(event, index) in events"
          :key="event.event_id"
        >
          <v-form :ref="'form-event-' + event.event_id">
            <!-- ******************************************** -->
            <!-- タイトル -->
            <!-- ******************************************** -->
            <v-row>
              <v-col cols="9">
                <v-text-field
                  v-model.trim="event.title"
                  outlined
                  required
                  persistent-hint
                  label="イベント名"
                  counter="30"
                  :rules="[valiRules.required, valiRules.max30]"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <!-- アクティブ -->
                <v-switch
                  class="ma-0 pa-0 ml-3"
                  v-model="event.is_active"
                  inset
                  :label="event.is_active ? '表示中' : '非アクティブ'"
                  color="accent"
                  hint="*イベントを非表示にできます"
                  persistent-hint
                  @change="updateColumn(event, 'is_active')"
                ></v-switch>
              </v-col>
            </v-row>

            <!-- ******************************************** -->
            <!-- 画像 -->
            <!-- ******************************************** -->
            <v-row class="mt-5">
              <v-col cols="6"
              >
                <v-sheet
                  class="mt-n5 mb-5"
                  v-if="event.image_url"
                >
                  <!-- サムネイル -->
                  <small>設定中の画像</small>
                  <v-img
                    max-height="600"
                    contain
                    :src="event.image_url"
                  >
                  </v-img>
                </v-sheet>
                <!-- インプット -->
                <v-file-input
                  v-model="updateImages[`${event.event_id}`]"
                  accept="image/png, image/jpeg"
                  outlined
                  chips
                  show-size
                  required
                  prepend-icon="mdi-image"
                  label="アイキャッチ画像"
                  hint="推奨の縦横サイズは[500px : 400px]程度の縦長"
                  persistent-hint
                  :rules="event.create ? [valiRules.required, valiRules.image] : [valiRules.image]"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip
                      color="primary"
                      label small
                    >
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-col>

              <!-- ******************************************** -->
              <!-- 本文 -->
              <!-- ******************************************** -->
              <v-col cols="6">
                <tiny-editor
                  v-if="contentStyle"
                  v-bind:text.sync="event.text"
                  :apiAdmin="apiAdmin"
                  :shopData="shopData"
                  :contentStyle="contentStyle"
                  :max="textMax"
                  :height="650"
                />
                <small
                  v-if="events.length == 1"
                >背景・文字は実際のHPのカラーです</small>
              </v-col>
            </v-row>

            <!-- ******************************************** -->
            <!-- ボタン -->
            <!-- ******************************************** -->
            <v-row no-gutters class="mt-7">
              <!-- ボタン -->
              <div class="ml-auto">
                <v-btn
                  class="ml-2 mr-3"
                  v-if="index > 0 && events.length > 1"
                  :disabled="event.create"
                  depressed small
                  color="primary"
                  @click="eventToTop(index)"
                >先頭へ</v-btn>
                <!-- <v-btn
                  :disabled="event.create"
                  depressed small
                  color="primary"
                >LINE配信</v-btn> -->
                <v-btn
                  v-if="event.create"
                  depressed small
                  color="primary"
                  @click="createRow(event)"
                >登録</v-btn>
                <v-btn
                  v-else
                  depressed small
                  color="primary"
                  @click="updateRow(event)"
                >更新</v-btn>
                <v-btn
                  class="ml-2"
                  text
                  depressed small
                  color="primary"
                  @click="deleteRow(index)"
                >削除</v-btn>
              </div>
            </v-row>
          </v-form>

          <!-- 非表示オーバーレイ -->
          <v-fade-transition>
            <v-overlay
              v-if="!event.is_active"
              absolute
              z-index="2"
              color="accent"
            >
              <v-btn
                color="primary"
                @click="event.is_active = !event.is_active ; updateColumn(event, 'is_active')"
              >表示中にする</v-btn>
            </v-overlay>
          </v-fade-transition>
        </v-card>

        <!-- もっとボタン -->
        <div
          v-if="events.length >= this.queryLimit"
        >
          <v-btn
            :disabled="disableLoadMore"
            color="primary"
            @click="loadMore"
          >更に読み込む
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="false"
    >
      {{ loadingMessage }}
    </loader>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import moment from "moment";
import $literals from '@/literals.js'
import { ApiTool, CheckTokenError, ValidationRules } from '@/module.js'
import Loader from '@/components/_Loader.vue'
import BannerHint from "@/components/_BannerHint.vue";
import TinyEditor from "@/components/_TinyEditor.vue"

export default {
  components: {
    'loader': Loader,
    'banner-hint': BannerHint,
    'tiny-editor': TinyEditor,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      maxEventId: 0,
      events: [],
      textMax: 5000,
      updateImages: [],
      queryLimit: 5,
      queryOffset: 0,
      disableLoadMore: false,
      contentStyle: '',
      loading: false,
      loadingMessage: '',
      valiRules: ValidationRules,
      snackbar: {open: false, color: 'primary', message: ''},
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
    }
  },

  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
  },

  created() {
    this.adminApi.setToken(this.serverToken)

    this.getData()
    .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
  },

  methods: {
    async getData() {
      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderLoading

      await Promise.all([
        this.getSiteEvent(),
        this.getSiteSetting(),
      ])

      this.loading = false
    },

    //もっと読み込む
    loadMore() {
      this.getSiteEvent()
      .catch(error => {
        if (CheckTokenError(error)) this.$emit('reset')
      })
    },

    //新規追加
    addEvent() {
      this.events.unshift({
        event_id: ++this.maxEventId,
        is_active: true,
        title: '',
        text: '',
        image_url: '',
        create: true
      })
    },

    //**************************************************
    //**************************************************
    //                    APIコール
    //**************************************************
    //
    // API req: GET
    //
    getSiteEvent() {
      const query = {limit: this.queryLimit, offset: this.queryOffset}

      return this.adminApi.getReqWithAuth('site-event/', query).then( records => {
        if (!records || !records.length) {
          this.disableLoadMore = true
          return
        }

        //id最大値の取得
        const maxRecordId = Math.max(...records.map( row => parseInt(row.event_id) ))
        this.maxEventId = maxRecordId > this.maxEventId ? maxRecordId : this.maxEventId

        records.map( row => { this.events.push(row) })
        this.queryOffset = this.queryOffset + this.queryLimit
      })
    },

    // API req: site_setting
    getSiteSetting() {
      return this.adminApi.getReqWithAuth('site-setting/').then( data => {
        if (!data) return
        this.contentStyle = 'body#tinymce {color: ' + (data.is_dark_theme ? 'white' : 'black') + ';' +
                            'background-color: ' + (data.secondary_color || 'grey') + ';}'
      })
    },

    //**************************************************
    //新規登録
    //**************************************************
    createRow(event) {
      if (!this.$refs['form-event-' + event.event_id][0].validate() ||
        !event.text || !event.title || !this.updateImages[`${event.event_id}`]
      ) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderInTarnsaction

      const formData = new FormData();
      formData.append('is_active', event.is_active)
      formData.append('title', event.title)
      formData.append('text', event.text)
      formData.append('image_url', this.updateImages[event.event_id])

      this.adminApi.apiReqWithData('POST', 'create/site-event/', formData).then( response => {
        event.image_url = response.image_url
        event.event_id = response.event_id
        delete this.updateImages[`${event.event_id}`]
        event.create = false
        this.queryOffset++

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successCreateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //**************************************************
    //更新
    //**************************************************
    updateRow(event) {
      if (!this.$refs['form-event-' + event.event_id][0].validate() ||
        !event.text || !event.title || (!event.image_url && !this.updateImages[event.event_id])
      ) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      const apiPath = 'update/site-event/' + event.event_id
      const formData = new FormData()
      formData.append('is_active', event.is_active)
      formData.append('title', event.title)
      formData.append('text', event.text)
      formData.append('image_url', this.updateImages[event.event_id] || event.image_url)

      this.adminApi.apiReqWithData('PUT', apiPath, formData).then( response => {
        if (response.NoRowsAffected) {
          this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoNoRowsAffected, open: true}}
          return
        }

        delete this.updateImages[event.event_id]
        event.image_url = response.image_url

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successUpdateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    },

    //**************************************************
    //カラム更新
    //**************************************************
    updateColumn(event, columnName) {
      const apiPath = 'update/site-event/' + event.event_id + '/column/' + columnName
      const formData = new FormData()
      formData.append(columnName, event[columnName])

      this.adminApi.apiReqWithData('PUT', apiPath, formData).then(() => {
        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successUpdateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    },

    //**************************************************
    //削除
    //**************************************************
    deleteRow(index) {
      if (this.events[index].create) {
        this.events.splice(index, 1)
        return
      }

      const apiPath = 'delete/site-event/' + this.events[index].event_id

      this.adminApi.apiReqWithData('DELETE', apiPath).then(() => {
        this.events.splice(index, 1)
        this.queryOffset--

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successDeleteSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    },

    //**************************************************
    //先頭へ（created_atを最新に更新）
    //**************************************************
    eventToTop(index) {
      const event = this.events[index]

      if (!this.$refs['form-event-' + event.event_id][0].validate() ||
        !event.text || !event.title || (!event.image_url && !this.updateImages[event.event_id])
      ) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      const apiPath = 'update/site-event/' + event.event_id + '/column/created_at'
      const formData = new FormData()
      formData.append('created_at', moment(new Date()).format('YYYYY-MM-DD HH:mm:ss'))

      this.adminApi.apiReqWithData('PUT', apiPath, formData).then(() => {
        const toTop = {...event}
        this.events.splice(index, 1)
        this.events.unshift(toTop)

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successUpdateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    },
  }
}
</script>

<style scoped>
</style>
